<template>
  <div class="folder">
    <ul class="project">
      <XTabGroup v-for="item in dataList" :key="item.name" :data="item" />
      <li>
        <Skeleton :loading="!dataList.length" animated class="skeleton" />
        <Skeleton :loading="!dataList.length" animated class="skeleton" />
      </li>
    </ul>
    <XTagGroup />
    <div v-if="false" class="folder-item" @click="onTrashCellClick">
      <XIcon icon-class="trash" style="font-size: 16px" />
      <Text
        style="margin-left: 8px"
        ellipsis
        :ellipsis-config="{ tooltip: true }"
        >回收站</Text
      >
      <span style="flex: 1"></span>
      <!-- <XIcon icon-class="more-col" class="trash-more" /> -->
      <Icon type="md-more" size="22" class="more-icon" />
    </div>
    <Modal
      class="folder-modal"
      title=" "
      draggable
      sticky
      scrollable
      :mask="true"
      :width="420"
      v-model="showModal"
      :loading="modalLoading"
      @on-ok="onModalOk"
    >
      <template v-if="modalKey == 'quit'">
        <p style="margin-bottom: 16px">退出清单</p>
        <p style="font-size: 14px">退出当前清单？（{{ modalData.name }}）</p>
      </template>
      <template v-else-if="modalKey == 'delete'">
        <p style="margin-bottom: 16px">删除清单</p>
        <p style="font-size: 14px">
          确定删除当前清单及清单下的所有任务？（{{ modalData.name }}）
        </p>
        <Radio
          style="margin-top: 10px"
          v-if="modalData.list && modalData.list.length > 0"
          v-model="deleteAll"
          >删除所有子清单？</Radio
        >
      </template>
      <template v-else-if="modalData.projectType == 1">
        <p style="margin-bottom: 12px">{{ modalTitle }}</p>
        <p style="margin: 50px 0px; text-align: center">
          近<InputNumber
            style="margin: 0px 10px"
            :min="2"
            :max="365"
            v-model="modalData.date"
          />天
        </p>
      </template>
      <template v-else>
        <XTabEdit :title="modalTitle" v-model="modalData" />
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
/// 清单列表
import XTabGroup from "@/components/XTabGroup";
import XTabEdit from "@/components/XTabEdit";
import XTagGroup from "@/components/XTagGroup";
import {
  projectList,
  projectAdd,
  projectUpdate,
  projectDelete,
  projectQuit,
  addressList,
  projectCountApi,
} from "@/common/api";

export default {
  components: {
    XTabGroup,
    XTabEdit,
    XTagGroup,
  },
  provide() {
    return {
      onMenuClick: (type, data) => this.showFolderMenu(type, data),
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    projectCount: {
      handler() {
        this.updateProjectCount();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      projectCount: (state) => state.projectCount,
      user: (state) => state.user,
    }),
    modalTitle() {
      if (this.modalKey == "delete") {
        return "删除";
      } else if (this.modalKey == "edit") {
        return "编辑";
      } else {
        if (this.modalData.projectType == 1) {
          return "自定义时间";
        } else {
          return "新建清单";
        }
      }
    },
    currentProject() {
      return this.$store.state.currentProject;
    },
  },
  data() {
    return {
      dataList: [],

      baseProejct: null,
      modalLoading: false,
      showModal: false,
      modalData: {},
      modalKey: "",
      deleteAll: false,
    };
  },
  methods: {
    // 获取所有清单
    async loadData() {
      this.dataList = []
      const projectCountList = await projectCountApi();
      const projects = await projectList();
      this.packageDataHandle(projects, projectCountList);

      const address = await addressList();
      this.$store.commit("updateAddresses", address);
    },
    async updateProjectCount() {
      const projectCountList = await projectCountApi();
      if (this.$store.state.baseProjectList?.length === 0) {
        projectList().then((res) => {
          this.packageDataHandle(res, projectCountList);
        });
      } else {
        this.packageDataHandle(
          this.$store.state.baseProjectList,
          projectCountList
        );
      }
    },
    packageDataHandle(baseList, countList) {
      baseList.map((item1) => {
        countList.map((item2) => {
          if (item1.projectIdStr === item2.projectId.toString()) {
            item1.executingTaskCount = item2.executingTaskCount || 0;
            item1.completedTaskCount = item2.completedTaskCount || 0;
            item1.noteCount = item2.noteCount || 0;
          }
        });
      });

      this.$store.commit("updateBaseProjects", baseList);
      this.dataList = this.packageData(baseList)
      if (JSON.stringify(this.$store.state.projectList) !== JSON.stringify(this.dataList)) {
        setTimeout(() => {
          this.$store.commit("updateProjects", this.dataList);
        }, 0);
      }
      // 当没有选中清单时默认选中收集箱
      if (this.currentProject.projectIdStr) {
        this.$router.replace(
          "/manager/work/" + this.currentProject.projectIdStr
        );
        return;
      }
      if (this.$route.params.project.length == 0 && this.baseProejct) {
        this.$store.commit("projectChanged", this.baseProejct);
        this.$router.replace("/manager/work/" + this.baseProejct.projectIdStr);
      }
    },
    // 组装数据
    packageData(list) {
      // 接口会返回所有清单和日期清单两个文件夹，需要过滤掉
      let allProject = {};
      let dateProject = {};
      let dateList = []; // 日期清单列表
      let normalList = []; // 自定义清单列表
      list.forEach((item) => {
        if (item.hide) return;
        item.title = item.name;
        item.expand = true;
        if (item.projectIdStr == "-2" + item.userId) {
          // 所有清单 -2 + userId
          allProject = item;
          allProject.isAll = true;
          return;
        } else if (item.projectIdStr == "-1" + item.userId) {
          // 日期清单 -1 + userId
          dateProject = item;
          dateProject.projectType = 1;
          if (this.dataList.length == 3) {
            dateProject.expand = this.dataList[1].expand;
          }
          return;
        } else if (item.projectIdStr == "1" + item.userId) {
          // 收集箱 1 + userId
          this.baseProejct = item;
        }
        if (item.projectType == 1) {
          // 日期类型为1
          dateList.push(item);
        } else {
          // 其余为自定义清单
          normalList.push(item);
        }
      });
      dateList = dateList.sort((a, b) => b.positionWeight - a.positionWeight);
      dateProject.list = dateList;
      normalList = this.listToTree(normalList);
      if (this.$route.params.project == allProject.projectIdStr) {
        this.$store.commit("projectChanged", allProject);
      }
      return [
        allProject,
        dateProject,
        {
          expand: true,
          name: "清单",
          list: normalList,
        },
      ];
    },
    /// 清单列表转清单树
    listToTree(list) {
      const obj = {};
      list.forEach((item) => {
        item.list = [];
        obj[item.projectIdStr] = item;
      });
      const parentList = [];
      list.forEach((item) => {
        const parent = obj[item.parentProjectIdStr];
        if (parent) {
          item.parentProjectName = parent.name;
          parent.list.push(item);
          parent.list.sort((a, b) => b.positionWeight - a.positionWeight);
        } else {
          parentList.push(item);
        }
      });
      parentList.sort((a, b) => b.positionWeight - a.positionWeight);
      return parentList;
    },
    // 菜单按钮点击
    showFolderMenu(key, data) {
      this.modalKey = key;
      if (key == "add") {
        let positionWeight = -99;
        if (this.modalData.list && this.modalData.list.length > 0) {
          let model = this.modalData.list[this.modalData.list.length - 1];
          positionWeight = model.positionWeight - 1;
        }
        let isDate = data.projectType == 1;
        let iconUrl =
          "https://www.yimutodo.com/images/icon/ic_project_default.png";
        if (isDate) {
          iconUrl = "https://www.yimutodo.com/images/icon/ic_project_date.png";
        }
        this.modalData = {
          date: 2,
          parentProjectId: data.projectIdStr,
          parentProjectName: data.name,
          projectType: isDate ? 1 : 0,
          positionWeight,
          iconUrl,
        };
      } else {
        this.modalData = Object.assign({ isEdit: true }, data);
      }
      setTimeout(() => {
        this.showModal = true;
      }, 0);
    },
    onModalOk() {
      this.modalLoading = true;
      if (this.modalData.projectType == 1) {
        this.modalData.name = `近${this.modalData.date}天`;
      }
      if (this.modalKey == "add") {
        if (this.modalData.projectType == 1) {
          this.modalData.projectId = `${this.modalData.date}00${this.user.userId}`;
        }
        projectAdd(this.modalData).then((res) => {
          this.modalLoading = false;
          if (res) {
            this.showModal = false;
            this.loadData();
          }
        });
      } else if (this.modalKey == "edit") {
        if (this.modalData.iconLocalUrl) {
          this.modalData.iconUrl = this.modalData.iconLocalUrl;
          delete this.modalData.iconLocalUrl;
        }
        projectUpdate(this.modalData).then((res) => {
          this.modalLoading = false;
          if (res) {
            this.showModal = false;
            this.loadData();
          }
        });
      } else if (this.modalKey == "delete") {
        projectDelete({ projectIdStr: this.modalData.projectIdStr }).then(
          (res) => {
            this.modalLoading = false;
            if (res) {
              this.showModal = false;
              this.loadData();
              if (this.modalData.projectIdStr == this.$route.params.project) {
                this.$store.commit("projectChanged", this.baseProejct);
                this.$router.replace(
                  "/manager/work/" + this.baseProejct.projectIdStr
                );
              }
            }
          }
        );
      } else if (this.modalKey == "quit") {
        projectQuit({ projectId: this.modalData.projectIdStr }).then((res) => {
          this.modalLoading = false;
          if (res) {
            this.showModal = false;
            this.loadData();
          }
        });
      }
    },
    onTrashCellClick() {
      this.$router.push({ name: "recycler" });
    },
  },
};
</script>

<style lang="less" scoped>
.folder {
  position: relative;
  min-width: 240px;
  height: calc(100% - 110px);
  box-sizing: border-box;
  background-color: var(--vp-c-bg);
  width: auto;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .project {
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 8px;
    .skeleton {
      margin: 10px 0;
      padding: 0 10px 0 20px;
    }
  }
  .folder-item {
    height: 40px;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: var(--vp-c-bg-alt);
    .tag-add {
      font-size: 20px;
      display: none;
    }
    .trash-more {
      font-size: 14px;
      display: none;
    }
    &:hover {
      // background-color: #f3f3f5;
      .tag-add {
        display: inline !important;
      }
      .trash-more {
        display: inline !important;
      }
    }
  }
}
</style>
