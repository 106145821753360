<template>
  <li class="tab-group">
    <div :class="{ 'group-title': true, selected}" @click="onGroupClick">
      <XArrow ref="groupArrow" :show="showArrow" :open="data.expand" icon="arrow_thin" size="10" @onArrowChanged="onArrowChanged" />
      <Text class="title" ellipsis :ellipsis-config="{ tooltip: true }">{{ data.name }}</Text>
      <Icon v-if="!data.isAll" type="md-add-circle" class="add" @click.stop="onAddClick" />
    </div>
    <XTabItem v-show="data.expand" :data="data" />
  </li>
</template>

<script>

import XTabItem from "@/components/XTabItem"
import XArrow from "@/components/XArrow"

export default {
  components: {
    XTabItem,
    XArrow
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
  },
  inject: ['onMenuClick'],
  data() {
    return {
      open: true,
    }
  },
  computed: {
    showArrow() {
      return this.data.list && this.data.list.length > 0
    },
    selected() {
      return this.data.isAll && this.data.projectIdStr == this.$route.params.project;
    },
  },
  methods: {
    onGroupClick() {
      if (this.data.title == '所有') {
        if (this.data.projectIdStr != this.$store.state.currentProject.projectIdStr) {
          this.$store.commit('projectChanged', this.data);
          this.$router.push('/manager/work/' + this.data.projectIdStr);
        }
      } else {
        this.$refs.groupArrow.onArrowClick();
      }
    },
    onArrowChanged(value) {
      // this.open = value;
      let copyData = this.data;
      copyData.expand = value;
    },
    onAddClick() {
      console.log(this.data);
      this.onMenuClick('add', this.data);
    },
  }
}
</script>

<style lang="less" scoped>
  .tab-group {
    background-color: var(--vp-c-bg);
    .group-title {
      border-radius: 8px;
      color: var(--vp-c-text-1);
      &:hover {
        background-color: var(--vp-c-bg-alt);
        color: var(--vp-c-text-1);
        .add {
          display: inline;
        }
      }
      &.selected {
        // background-color: rgba(0,137,255,.12) !important;
        background-color: var(--vp-c-bg-blue) !important;
        border: 1px dashed #1b9aee;
      }
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 5px 20px 5px 10px;
      margin-bottom: 4px;
      height: 40px;
      .title {
        flex: 1;
        font-size: 14px;
        color: var(--vp-c-text-1);
        font-weight: 500;
        margin-left: 10px;
      }
      .add {
        color: var(--vp-c-text-1);
        font-size: 20px;
        display: none;
      }
    }
  }
</style>