<template>
  <div
    :class="{ 'tab-node': true, hover: hover, selected: selected }"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @click="onClick"
    @contextmenu.prevent.stop="onMoreClick"
  >
    <XArrow
      :show="showArrow"
      :open="data.expand"
      @onArrowChanged="onArrowClick"
    />
    <Image v-if="data.iconUrl" :src="data.iconUrl" width="16px" alt="icon">
      <template #placeholder>
        <Icon type="ios-image-outline" size="24" color="#ccc" />
      </template>
      <template #error>
        <Icon type="ios-image-outline" size="24" color="#ccc" />
      </template>
    </Image>
    <Text class="title" ellipsis :ellipsis-config="{ tooltip: true }">
      {{ data.name }}
      <span v-if="data.projectType === 0" class="project-count">
        ({{ getTaskCount() }})
      </span>
    </Text>
    <!-- <Text v-show="!hover && data.progress && data.progress > 0" class="count" ellipsis>{{ data.progress }}</Text> -->
    <!-- <XIcon
      v-show="hover && canContext"
      icon-class="more-col"
      class="more-operate"
      style="font-size: 16px"
      @click.stop="onMoreClick"
    /> -->
    <Icon
      v-show="hover && canContext"
      type="md-more"
      @click.stop="onMoreClick"
      size="22"
      class="more-icon"
    />
  </div>
</template>

<script>
import XArrow from "./XArrow";

export default {
  name: "XTabNode",
  components: {
    XArrow,
  },
  props: {
    data: {
      required: true,
      type: Object,
      default: () => {},
    },
  },
  inject: ["onMenuClick"],
  mounted() {
    if (this.$route.params.project == this.data.projectIdStr) {
      this.$store.commit("projectChanged", this.data);
    }
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    selected() {
      return this.data.projectIdStr == this.$route.params.project;
    },
    showArrow() {
      return this.data.list && this.data.list.length > 0;
    },
    isOpen() {
      let open = this.$store.state.openFolder[this.data.projectIdStr];
      return open != null ? open : true;
    },
    showList() {
      return this.showArrow && this.isOpen;
    },
    canContext() {
      if (this.data.permission == "PREVIEW") return false;
      if (this.data.projectType == 1) {
        if (["今天", "明天", "本周", "本月"].indexOf(this.data.name) > -1)
          return false;
      }
      return true;
    },
    isNormalDefault() {
      return "1" + this.data.userId == this.data.projectIdStr;
    },
  },
  methods: {
    getTaskCount() {
      // console.log(this.data);
      const data = this.data;

      let count = 0;
      // console.log(data.executingTaskCount)
      if (data.executingTaskCount) {
        count += data.executingTaskCount;
      }
      // console.log(data.noteCount)
      if (data.noteCount) {
        count += data.noteCount;
      }
      
      return count;
    },
    onMouseEnter() {
      this.hover = true;
    },
    onMouseLeave() {
      this.hover = false;
    },
    onClick() {
      if (
        this.data.projectIdStr != this.$store.state.currentProject.projectIdStr
      ) {
        this.$store.commit("projectChanged", this.data);
        this.$router.push("/manager/work/" + this.data.projectIdStr);
      }
    },
    onAddClick() {
      this.onMenuClick("add");
    },
    onMoreClick(e) {
      if (!this.canContext) return;
      let items = [];
      if (this.data.projectType == 1) {
        items = [
          {
            label: "编辑",
            onClick: () => this.onMenuClick("edit", this.data),
          },
          {
            label: "删除",
            onClick: () => this.onMenuClick("delete", this.data),
          },
        ];
      } else {
        if (this.isNormalDefault) {
          items = [
            {
              label: "添加子清单",
              onClick: () => this.onMenuClick("add", this.data),
            },
          ];
        } else {
          items = [
            {
              label: "编辑",
              onClick: () => this.onMenuClick("edit", this.data),
            },
            {
              label: "添加子清单",
              onClick: () => this.onMenuClick("add", this.data),
            },
          ];
          if ((this.data.projectIdStr + "").endsWith(this.data.userId)) {
            items.push({
              label: "删除",
              onClick: () => this.onMenuClick("delete", this.data),
            });
          } else {
            items.push({
              label: "退出",
              onClick: () => this.onMenuClick("quit", this.data),
            });
          }
        }
      }

      e.preventDefault();
      this.$contextmenu({
        x: e.x,
        y: e.y,
        items: items,
      });
    },
    onArrowClick(value) {
      // this.$store.commit('openChanged', {[this.data.projectId]: value});
      let copyData = this.data;
      copyData.expand = value;
    },
  },
};
</script>

<style lang="less" scoped>
.tab-node {
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;
  color: var(--vp-c-text-2);
  border-radius: 8px;
  padding: 0px 8px;
  position: relative;
  height: 40px;
  border-radius: 8px;
  border: 1px dashed var(--vp-c-bg-node);
  &:hover {
    background-color: var(--vp-c-bg-blue);
    & .title {
      color: var(--vp-c-text-1);
    }
  }
  &.selected {
    // background-color: #F5FAFF;
    background-color: var(--vp-c-bg-blue) !important;
    border: 1px dashed #1b9aee;
  }
  .title {
    font-size: 14px;
    font-weight: 400;
    flex: 1;
    margin: 0 8px;
    color: var(--vp-c-text-2);
    .project-count {
      margin-left: 5px;
    }
  }
  .count {
    font-size: 12px;
    color: var(--vp-c-text-1);
  }

  .node-operate {
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: #fff;
    font-size: 18px;
    &.selected {
      background-color: #ecf1ff;
    }
  }
  .more-operate {
    color: var(--vp-c-text-1);
    font-size: 30px;
    margin-right: 4px;
  }
  .add-operate {
    color: #969ea8ff;
    font-size: 20px;
  }
  .menu-dropdown {
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: blue;
  }
  .menu-box {
    display: inline-block;
    width: 1px;
    height: 1px;
  }
}
.node-sub {
  padding-left: 16px;
}
</style>
